<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bxs-cart"></i>
            {{ $t("merchants.label") }}
          </template>
          <form-input 
            type="checkbox"
            label="common.active" 
            icon="bx-check-circle"
            v-model="item.active"
            switch
            size="lg"
          />
          <form-input 
            label="common.name" 
            icon="bx-purchase-tag"
            v-model="item.name"
            rules="required"
          />
          <form-input 
            type="country"
            icon="bx-globe"
            rules="required"
            label="common.country"
            v-model="item.country"
          />
          <form-input 
            type="select"
            bitmask
            label="merchants.features" 
            icon="bx bx-hive"
            :options="resource.features | transMap"
            v-model="item.features"
          />
        </b-card>

        <b-card>
          <template #header>
            <i class="bx bxs-edit-location"></i>
            {{ $t("location_groups.title") }}
          </template>

          <form-input 
            type="item"
            multiple
            label="location_groups.title" 
            icon="bx bxs-edit-location"
            resource-name="location_groups"
            v-model="item.location_groups"
          />
        </b-card>
      </div>
      <div class="col mb-4">

        <b-card no-body>
          <template #header>
            <i class="bx bx-message-detail"></i>
            {{ $t("merchants.notifications") }}
          </template>

          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col"><i class="small bx bx-message-detail"></i> Event</th>
                <th scope="col" v-for="channel in resource.channels" :key="channel.code">
                  <i class="small bx" :class="channel.icon"></i>
                  {{$t(channel.label)}}
                </th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="({ code: event },i) in events" :key="i">
                <td>{{$t(`merchants.events.${event}`)}}</td>
                <td v-for="channel in resource.channels" :key="channel.code">
                  <b-form-checkbox 
                    v-model="item.channels[event]"
                    switch
                    :value="(item.channels[event] | channel.code)"
                    :uncheckedValue="(item.channels[event] ^ channel.code)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

        </b-card>

        <b-card v-if="hasPostpayments">
          <template #header>
            <i class="bx bx-coin"></i>
            {{ $t("merchants.postpayments.title") }}
          </template>

          <form-input
            label="merchants.postpayments.payment-url" 
            icon="bx-link"
            rules="required"
            v-model="item.postpayments['payment-url']"
          />
        </b-card>

        <b-card v-if="hasBranding">
          <template #header>
            <i class="bx bxs-brush"></i>
            {{ $t("merchants.branding.title") }}
          </template>

          <form-input
            label="merchants.branding.name" 
            icon="bx-purchase-tag"
            rules="required"
            v-model="item.branding['name']"
          />

          <form-input
            label="merchants.branding.url" 
            icon="bx-home"
            rules="required"
            v-model="item.branding['url']"
          />

          <form-input
            label="merchants.branding.contact-url" 
            icon="bx-phone"
            rules="required"
            v-model="item.branding['contact-url']"
          />

          <form-input
            type="email"
            label="merchants.branding.from-address" 
            icon="bx-mail-send"
            rules="email"
            :placeholder="$t('common.use-default')"
            v-model="item.branding['from-address']"
          />

          <form-input
            label="merchants.branding.email-theme" 
            icon="bx-palette"
            :placeholder="$t('common.use-default')"
            v-model="item.branding['email-theme']"
          />

        </b-card>

        <b-card v-if="hasFetching">
          <template #header>
            <i class="bx bx-import"></i>
            {{ $t("merchants.fetching.title") }}
          </template>

          <form-input 
            label="merchants.fetch_service" 
            icon="bx-bolt-circle"
            type="select"
            v-model="item.fetch_service" 
            :reduce="opt => opt" 
            :options="resource.fetchServices" 
              />
        </b-card>

      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import FormInput from "@/components/Form/Input";
import ItemEditTemplate from '@/components/ItemEditTemplate.vue';
import { FEATURE_BRANDING, CHANNEL_EMAIL, FEATURE_POSTPAYMENTS, FEATURE_FETCHING } from '@/resources/Merchant';

export default {
  extends: ItemEdit,
  components: { FormInput, ItemEditTemplate },
  data() {
    
    return {
      CHANNEL_EMAIL,
    }
  },
  computed: {
    hasBranding() {
      return this.item.features & FEATURE_BRANDING
    },
    hasPostpayments() {
      return this.item.features & FEATURE_POSTPAYMENTS
    },
    hasFetching() {
      return this.item.features & FEATURE_FETCHING
    },
    events() {
      return this.resource.events.filter(obj => !obj.$feature || this.item.features & obj.$feature)
    }
  },
};
</script>